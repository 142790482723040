<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4>Order Detail</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>Customer Name</label>
              <h6>{{ customer_name }}</h6>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Order Code</label>
              <h6>{{ order_code }}</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>Venue</label>
              <h6>{{ name_venue }}</h6>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Table</label>
              <h6>{{ table_name }}</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>Payment Type</label>
              <h6>{{ name_payment_type }}</h6>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Status Payment</label>
              <h6>{{ status_payment }}</h6>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Total Price</label>
          <h5>Rp. {{ formatPrice(total_price) }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "Detail Order",
    };
  },
  props: [
    "customer_name",
    "order_code",
    "name_venue",
    "table_name",
    "name_payment_type",
    "status_payment",
    "total_price",
  ],

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
