<template>
  <div class="card">
    <div class="card-header">
      <h4>Payment Detail</h4>
    </div>
    <div class="card-body">
      <div v-if="discount_type">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>Discount Type</label>
              <h6>{{ discount_type_name }}</h6>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Total Discount</label>
              <h6>Rp. {{ formatPrice(discount) }}</h6>
            </div>
          </div>
        </div>
        <div class="row" v-if="discount_type == 'APPLICATION'">
          <div class="col">
            <div class="form-group">
              <label>Loyalty</label>
              <h6>Rp. {{ formatPrice(use_loyalty) }}</h6>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Point</label>
              <h6>Rp. {{ formatPrice(use_point) }}</h6>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Member</label>
              <h6>Rp. {{ formatPrice(use_member) }}</h6>
            </div>
          </div>
        </div>
        <div
          class="form-group"
          v-if="discount_type == 'PERCENTAGE' || discount_type == 'REBATE'"
        >
          <div class="form-group">
            <label>Acc By</label>
            <h6>{{ acc_name }}</h6>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Payout</label>
            <h6>Rp. {{ formatPrice(total_payout) }}</h6>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Change</label>
            <h6>Rp. {{ formatPrice(change) }}</h6>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Total Price</label>
        <h5>Rp. {{ formatPrice(total_price) }}</h5>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "Order Payment",
    };
  },
  props: [
    "acc_name",
    "change",
    "discount",
    "discount_type",
    "discount_type_name",
    "total_payout",
    "use_loyalty",
    "use_point",
    "use_member",
    "total_price",
  ],

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
