<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>Order {{ order.order_code || "" }}</h1></template
      >
      <template v-slot:item-slot>
        <router-link class="breadcrumb-item" :to="{ name: 'OrderIndex' }"
          >Order</router-link
        >
        <div class="breadcrumb-item">
          {{ order.order_code || "" }}
        </div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>
      <v-progress-circular
        v-if="loading"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <div class="row" v-else>
        <div class="col-md-6">
          <detail-order
            :customer_name="order.customer_name"
            :order_code="order.order_code"
            :name_venue="order.name_venue"
            :table_name="order.table_name"
            :name_payment_type="order.name_payment_type"
            :status_payment="order.status_payment"
            :total_price="order.total_price"
          ></detail-order>
        </div>
        <div class="col-md-6">
          <order-payment
            :discount_type_name="order.order_payment.discount_type_name"
            :discount="order.order_payment.discount"
            :change="order.order_payment.change"
            :discount_type="order.order_payment.discount_type"
            :acc_name="order.order_payment.acc_name"
            :total_payout="order.order_payment.total_payout"
            :total_price="order.order_payment.total_price"
            :use_loyalty="order.order_payment.use_loyalty"
            :use_point="order.order_payment.use_point"
            :use_member="order.order_payment.use_member"
          ></order-payment>
        </div>
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h4>Table Order</h4>
            </div>
            <div class="card-body">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Product Name</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Price</th>
                    <th scope="col">Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(order_detail, index) in order.order_detail"
                    :key="index"
                  >
                    <td>{{ order_detail.name_product }}</td>
                    <td>
                      {{ order_detail.count }}/{{ order_detail.name_unit }}
                    </td>
                    <td>Rp. {{ formatPrice(order_detail.price) }}</td>
                    <td>Rp. {{ formatPrice(order_detail.total_price) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { apiGet } from "../../services/api";
import DetailOrder from "./DetailOrder.vue";
import OrderPayment from "./OrderPayment.vue";

export default {
  name: "OrderShow",
  components: { DetailOrder, OrderPayment },
  data() {
    return {
      name: "Detail Order",
      order: [],
      loading: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getData() {
      let id = this.$route.params.id;
      this.loading = true;

      apiGet("order/" + id).then((res) => {
        this.order = res.data.data;
        this.loading = false;
      });
    },
  },
};
</script>
